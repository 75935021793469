import { OrderCardServiceLinkage, Prisma } from '@prisma/client';
import { CreateOrderCardServiceLinkageDTO } from './dto/create-order-card-service-linkage.dto';
import { UpdateOrderCardServiceLinkageDTO } from './dto/update-order-card-service-linkage.dto';
import { QueryOrderCardServiceLinkageDTO } from './dto/query-order-card-service-linkage.dto';

import {
  BaseResourceTypeMappings,
  MapPossibleIncludesToRequiredDepth,
} from '@aa/nest/common';
import { XOR } from '@aa/ts/common';

export type OrderCardServiceLinkageResourceTypeMappings =
  BaseResourceTypeMappings<
    OrderCardServiceLinkage,
    OrderCardServiceLinkage &
      Exclude<
        Partial<
          Prisma.OrderCardServiceLinkageGetPayload<{
            include: MapPossibleIncludesToRequiredDepth<
              Prisma.OrderCardServiceLinkageInclude,
              4
            >;
          }>
        >,
        OrderCardServiceLinkage
      >,
    {
      orderCardId: number;
      offeredServiceId: number;
    },
    XOR<
      Prisma.OrderCardServiceLinkageCreateInput,
      Prisma.OrderCardServiceLinkageUncheckedCreateInput
    >,
    XOR<
      Prisma.OrderCardServiceLinkageUpdateInput,
      Prisma.OrderCardServiceLinkageUncheckedUpdateInput
    >,
    Prisma.OrderCardServiceLinkageWhereInput,
    Prisma.OrderCardServiceLinkageWhereUniqueInput,
    Prisma.OrderCardServiceLinkageInclude,
    Prisma.OrderCardServiceLinkageOrderByWithRelationInput,
    CreateOrderCardServiceLinkageDTO,
    UpdateOrderCardServiceLinkageDTO,
    QueryOrderCardServiceLinkageDTO
  >;

export const orderCardServiceLinkageCompositeKeyOrder: (keyof OrderCardServiceLinkageResourceTypeMappings['resourceT'])[] =
  ['orderCardId', 'offeredServiceId'];
