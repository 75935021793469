import {
  actionLogActions,
  ActionLogsListViewComponent,
  ConfirmDialogComponent,
  ConfirmDialogData,
  orderActions,
  orderCardActions,
  OrderFormModalComponent,
  OrderFormModalData,
  orderSourceActions,
  orderStatusActions,
  selectOrderSourceState,
  selectOrderState,
  selectOrderStatusState,
  StorageService,
} from '@aa/angular/core';
import {
  ActionLogResourceTypeMappings,
  OrderCardResourceTypeMappings,
  OrderResourceTypeMappings,
  OrderSourceResourceTypeMappings,
  OrderStatusResourceTypeMappings,
  UpdateOrderCardDTO,
} from '@aa/nest/resource';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  of,
} from 'rxjs';
import {
  cardFormArrayFields,
  CardFormModel,
  getCardFormFieldsAndLoadOptions,
  NewCardViewComponent,
  reduceFieldsToKeys,
} from '@aa/angular/customer';
import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  computeTextColorForBackground,
  getActionLogSummaryString,
} from '@aa/ts/common';
import {
  DataTableComponent,
  DataTableConfig,
} from '../../../../../core/src/lib/components/data-table/data-table.component';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { StaffAppState } from '../../state/index.reducers';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const flatLevelAttributes = ['value'];

@Component({
  selector: 'aa-order-detail-view',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    FormlyModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    NewCardViewComponent,
    ActionLogsListViewComponent,
    DataTableComponent,
  ],
  templateUrl: './order-detail-view.component.html',
  styleUrl: './order-detail-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailViewComponent {
  form = new FormGroup({});
  model: CardFormModel = {
    requestedServices: [],
    cardNumber: '',
    characterName: '',
    issuedYear: '',
    notes: '',
    playerName: '',
    pokemonType: '',
    productionCompany: '',
    serialNumber: '',
    setName: '',
    // tradingCardType: '',
    variation: '',
  };
  fields: FormlyFieldConfig[] = [
    // {
    //   template: '<p><strong>Provided Details</strong><p/>',
    // },
    // ...cardFormFields,
    // {
    //   template: '<p><strong>Staff Determined Details</strong></p>',
    // },
    // {
    //   key: 'value',
    //   type: 'number',
    //   props: {
    //     label: 'Value',
    //   },
    // },
  ];

  summaryTableConfig: DataTableConfig = {
    data$: of([]),
    columns: [
      {
        key: 'attribute',
        label: 'Attribute',
        accessor: (row) => `${row.attribute}: `,
        getStyle: () => ({
          'font-weight': '700',
          'min-width': 'fit-content',
          'text-wrap': 'nowrap',
        }),
        getHeaderStyle: () => ({ minWidth: 'fit-content' }),
      },
      {
        key: 'value',
        label: 'Value',
        getStyle: () => ({ width: '100%' }),
        getHeaderStyle: () => ({ width: '100%' }),
      },
    ],
    numItems$: of(0),
    hidePagination: true,
    hideTableHeaders: true,
    hideShadow: true,
  };

  orderId$: Observable<number>;
  order$: Observable<
    OrderResourceTypeMappings['resourceWithRelationsT'] | undefined | null
  >;
  orderStatuses$: Observable<
    OrderStatusResourceTypeMappings['resourceWithRelationsT'][]
  >;
  orderSources$: Observable<
    OrderSourceResourceTypeMappings['resourceWithRelationsT'][]
  >;

  currentCard$ = new BehaviorSubject<
    OrderCardResourceTypeMappings['resourceWithRelationsT'] | null
  >(null);
  aiLoading$ = new BehaviorSubject<boolean>(false);

  actionLogsWhere?: ActionLogResourceTypeMappings['whereT'];
  actionLogsInclude?: ActionLogResourceTypeMappings['includeT'] = {
    user: {
      include: {
        staffProfile: true,
      },
    },
  };

  constructor(
    private readonly store: Store<StaffAppState>,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly storageService: StorageService,
    private readonly destroyRef: DestroyRef,
  ) {
    this.order$ = this.store
      .select((s) => selectOrderState(s).current)
      .pipe(takeUntilDestroyed());
    this.orderStatuses$ = this.store
      .select((s) => selectOrderStatusState(s).items)
      .pipe(takeUntilDestroyed());
    this.orderSources$ = this.store
      .select((s) => selectOrderSourceState(s).items)
      .pipe(takeUntilDestroyed());

    this.orderId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('id')!)),
      takeUntilDestroyed(),
    );

    getCardFormFieldsAndLoadOptions(store, destroyRef, true).subscribe(
      (formFields) => {
        this.fields = [
          {
            template: '<p><strong>Provided Details</strong><p/>',
          },
          ...formFields,
        ];
      },
    );

    this.store.dispatch(orderStatusActions.loadItems({}));
    this.store.dispatch(orderSourceActions.loadItems({}));
    this.store.dispatch(
      actionLogActions.loadItems({
        query: {
          include: {
            user: {
              include: {
                staffProfile: true,
              },
            },
          },
          orderBy: {
            createdAt: 'desc',
          },
        },
      }),
    );

    this.orderId$.subscribe((orderId) => {
      this.store.dispatch(
        orderActions.loadItem({
          id: orderId,
          include: {
            customerProfile: true,
            orderCards: {
              include: {
                orderCardServiceLinkages: {
                  include: {
                    offeredService: {
                      include: {
                        parentOfferedService: true,
                      },
                    },
                  },
                },
                orderCardAttributes: {
                  include: {
                    orderCardAttributeType: true,
                  },
                },
              },
            },
            orderServiceLinkages: {
              include: {
                offeredService: true,
              },
            },
            orderSource: true,
            orderStatus: true,
          },
        }),
      );
      this.actionLogsWhere = {
        OR: [
          {
            parentResourceType: 'order',
            parentResourceId: orderId,
          },
          {
            resourceType: 'order',
            resourceId: orderId,
          },
        ],
      };
    });

    this.currentCard$?.subscribe((card) => {
      const tradingCardType = card?.orderCardAttributes?.find(
        (ca) => ca.orderCardAttributeType?.fieldName == 'tradingCardType',
      )?.value;

      const patchValue: Record<string, any> = {
        // ...this.model,
        tradingCardType,
        requestedServices: card?.orderCardServiceLinkages
          ?.filter((li) => !li.offeredService?.parentOfferedServiceId)
          ?.map((sl) => sl.offeredServiceId)
          .reduce(
            (obj, id) => ({ ...obj, [id]: true }),
            {} as Record<number, boolean>,
          ),
        ...card?.orderCardAttributes
          ?.filter((attribute) => attribute.value)
          ?.reduce(
            (obj, attribute) => ({
              ...obj,
              [attribute.orderCardAttributeType?.fieldName]:
                cardFormArrayFields.includes(
                  attribute.orderCardAttributeType?.fieldName,
                ) &&
                attribute.value &&
                attribute.value.includes('[') &&
                attribute.value.includes(']')
                  ? JSON.parse(attribute.value)
                  : attribute.value,
            }),
            {} as CardFormModel,
          ),

        value: card?.value as any,
      };

      console.log(patchValue);

      for (const serviceLinkage of card?.orderCardServiceLinkages ?? []) {
        const service = serviceLinkage.offeredService;
        if (service.parentOfferedService) {
          const key = `${(
            service.parentOfferedService?.abbreviation ??
            service.parentOfferedService?.name
          )
            .toLowerCase()
            .replaceAll(' ', '')}${service.group?.replaceAll(' ', '')}Addon`;

          if (patchValue[key]) {
            if (Array.isArray(patchValue[key])) {
              patchValue[key] = [...patchValue[key], service.id];
            } else {
              patchValue[key] = [patchValue[key], service.id];
            }
          } else {
            patchValue[key] = service.id;
          }
        }
      }

      this.model = { ...(patchValue as any) };
    });

    this.summaryTableConfig = {
      ...this.summaryTableConfig,
      data$: this.order$.pipe(
        map((order) => [
          {
            attribute: 'Customer',
            value: `${order?.customerProfile?.firstName ?? ''} ${
              order?.customerProfile?.lastName ?? ''
            }`,
          },
          // {
          //   attribute: 'Requested Services',
          //   value: (order?.orderServiceLinkages ?? [])
          //     .map((li) => li.offeredService?.name)
          //     .join(', '),
          // },
          {
            attribute: 'Notes',
            value: order?.notes,
          },
        ]),
      ),
    };
  }

  async openEditForm() {
    const order = await firstValueFrom(this.order$);
    if (!order) return;
    const data: OrderFormModalData = {
      mode: 'update',
      model: {
        ...order,
        requestedServices: order.orderServiceLinkages?.map(
          (li) => li.offeredServiceId,
        ),
      },
    } as any;
    this.dialog.open(OrderFormModalComponent, {
      data,
      minWidth: '50vw',
      maxWidth: 'calc(100vw - 3rem)',
      maxHeight: 'calc(100vh - 3rem)',
      autoFocus: false,
    });
  }

  async promptDeleteItem() {
    const order = await firstValueFrom(this.order$);
    const data: ConfirmDialogData = {
      title: 'Are you sure you want to delete this item?',
      subtitle: 'This action cannot be undone.',
    };
    const confirmation = await lastValueFrom(
      this.dialog
        .open(ConfirmDialogComponent, {
          autoFocus: false,
          data,
        })
        .afterClosed(),
    );

    if (confirmation && order) {
      // TODO: make this also work with non 'id' primary keys
      this.store.dispatch(orderActions.deleteItem({ id: order.id }));
      this.router.navigate(['/']);
    }
  }

  getTextColorForBackground = computeTextColorForBackground;

  async setCurrentCard(
    orderCard: OrderCardResourceTypeMappings['resourceWithRelationsT'] | null,
  ) {
    this.form.reset();
    if (orderCard != null) {
      const copyOfOrderCard = structuredClone(orderCard);
      copyOfOrderCard.frontImageURL = (
        await lastValueFrom(
          this.storageService.getFreshURL(
            copyOfOrderCard.frontImageStorageSlug ?? '',
          ),
        )
      ).url;
      copyOfOrderCard.backImageURL = (
        await lastValueFrom(
          this.storageService.getFreshURL(
            copyOfOrderCard.backImageStorageSlug ?? '',
          ),
        )
      ).url;
      this.currentCard$.next(copyOfOrderCard);
    } else {
      this.currentCard$.next(orderCard);
    }
  }

  async handleImageClick(isBackImage = false) {
    const currentCard = await firstValueFrom(this.currentCard$);
    if (currentCard) {
      const newSrc = (
        await lastValueFrom(
          this.storageService.getFreshURL(
            isBackImage
              ? currentCard.backImageStorageSlug!
              : currentCard.frontImageStorageSlug!,
          ),
        )
      )?.url;
      this.enlargePhoto(newSrc);
    }
  }

  enlargePhoto(src: string) {
    const img = new Image();
    img.src = src;
    // const photoViewer = new PhotoViewer([img], {
    //   title: false,
    // });
  }

  async updateOrderStatus(newStatusId: number) {
    const orderId = await firstValueFrom(this.orderId$);
    if (orderId) {
      this.store.dispatch(
        orderActions.updateItem({
          id: orderId,
          data: {
            orderStatusId: newStatusId,
          },
        }),
      );
    }
  }

  async updateOrderSource(newSourceId: number) {
    const orderId = await firstValueFrom(this.orderId$);
    if (orderId) {
      this.store.dispatch(
        orderActions.updateItem({
          id: orderId,
          data: {
            orderSourceId: newSourceId,
          },
        }),
      );
    }
  }

  getActionLogSummary = getActionLogSummaryString;

  cancel() {
    this.setCurrentCard(null);
  }

  async save() {
    console.log('MODEL', this.model);
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const card = await firstValueFrom(this.currentCard$);
    const cardId = card?.id;
    const currentOrderCardAttributes = card?.orderCardAttributes;
    let requestedServices = [
      ...Object.entries(this.model.requestedServices)
        .filter(([key, value]) => value == true)
        .map(([key, value]) => parseInt(key)),
    ];
    for (const [key, value] of Object.entries(this.model)) {
      if (key.substring(key.length - 5) == 'Addon') {
        if (Array.isArray(value)) {
          requestedServices = [...requestedServices, ...value];
        } else {
          requestedServices = [...requestedServices, value as number];
        }
      }
    }

    const model: any = { ...this.form.value };
    const attributeKeys = reduceFieldsToKeys(this.fields);

    if (cardId) {
      this.store.dispatch(
        orderCardActions.updateCardWithAttributes({
          id: cardId,
          cardUpdates:
            Object.entries(model)
              .filter(([key, value]) => flatLevelAttributes.includes(key))
              .reduce(
                (obj, [key, value]) => ({
                  ...obj,
                  [key]: value,
                }),
                {} as UpdateOrderCardDTO,
              ) ?? {},
          offeredServicesIds: requestedServices,
          attributes: attributeKeys
            .filter(
              (key) =>
                key !== 'requestedServices' &&
                key.substring(key.length - 5) != 'Addon' &&
                !flatLevelAttributes.includes(key),
            )
            .map((key) => ({
              orderCardId: cardId,
              orderCardAttributeTypeId: currentOrderCardAttributes?.find(
                (ca) => ca.orderCardAttributeType.fieldName == key,
              )?.orderCardAttributeTypeId,
              fieldName: key,
              value:
                cardFormArrayFields.includes(key) && model[key]
                  ? JSON.stringify(model)
                  : model,
            })),
        }),
      );
    }
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }
}
