import { OrderCardServiceLinkageService } from './order-card-service-linkage.service';
import { ActionLogService } from './action-log.service';
import { AuthService } from './auth.service';
import { CardAttributeDetectionService } from './card-attribute-detection.service';
import { CustomerProfileService } from './customer-profile.service';
import { OfferedServiceService } from './offered-service.service';
import { OrderCardAttributeTypeService } from './order-card-attribute-type.service';
import { OrderCardAttributeService } from './order-card-attribute.service';
import { OrderCardService } from './order-card.service';
import { OrderServiceLinkageService } from './order-service-linkage.service';
import { OrderSourceService } from './order-source.service';
import { OrderStatusService } from './order-status.service';
import { OrderService } from './order.service';
import { PaymentService } from './payment.service';
import { RefreshTokenService } from './refresh-token.service';
import { StaffProfileService } from './staff-profile.service';
import { StorageService } from './storage.service';
import { UserRoleAssignmentService } from './user-role-assignment.service';
import { UserRoleService } from './user-role.service';
import { UserService } from './user.service';

export const angularCoreServices = [
  AuthService,
  CardAttributeDetectionService,
  StorageService,
  PaymentService,
  OrderService,
  OrderCardService,
  UserService,
  UserRoleService,
  UserRoleAssignmentService,
  RefreshTokenService,
  ActionLogService,
  OfferedServiceService,
  OrderServiceLinkageService,
  OrderSourceService,
  OrderStatusService,
  OrderCardAttributeService,
  OrderCardAttributeTypeService,
  CustomerProfileService,
  StaffProfileService,
  OrderCardServiceLinkageService,
];
