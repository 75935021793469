import { StaffProfileResourceTypeMappings } from '@aa/nest/resource';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  BaseFormModalComponent,
  baseFormModalImports,
  BaseResourceFormModelOptionsMappers,
  BaseResourceFormModalOptionsMapper,
  FormModalData,
} from '../../components/base-form-modal/base-form-modal.component';
import { CoreAppState } from '../../state/core-app.state';
import { staffProfileActions } from '../../state/staff-profile/staff-profile.actions';

export type StaffProfileFormModel = Partial<
  StaffProfileResourceTypeMappings['createDTO']
>;
export type StaffProfileFormModalData = FormModalData<StaffProfileFormModel>;

@Component({
  selector: 'aa-staff-profile-form-modal',
  standalone: true,
  imports: [...baseFormModalImports],
  templateUrl:
    '../../components/base-form-modal/base-form-modal.component.html',
  styleUrl: '../../components/base-form-modal/base-form-modal.component.scss',
})
export class StaffProfileFormModalComponent extends BaseFormModalComponent<
  StaffProfileFormModel,
  StaffProfileFormModalData
> {
  title = 'StaffProfile';
  fields: FormlyFieldConfig[] = [
    {
      key: 'firstName',
      type: 'input',
      props: {
        label: 'First Name',
        type: 'text',
        required: true,
      },
    },
    {
      key: 'lastName',
      type: 'input',
      props: {
        label: 'Last Name',
        type: 'text',
        required: true,
      },
    },
    {
      key: 'notes',
      type: 'input',
      props: {
        label: 'Note',
        type: 'text',
        required: false,
      },
    },
  ];

  createAction = staffProfileActions.createItem;
  updateAction = staffProfileActions.updateItem;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected override readonly data: StaffProfileFormModalData,
    protected override readonly dialogRef: MatDialogRef<StaffProfileFormModalComponent>,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(data, dialogRef, store);
  }
}
