<div class="container page-section">
  <mat-card>
    <img src="assets/images/large-logo.jpg" />
    <h1>Register</h1>
    <form [formGroup]="form" (submit)="submit()">
      <formly-form
        [form]="form"
        [fields]="fields"
        [model]="model"></formly-form>

      <p class="error">{{ error$ | async }}</p>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        [disabled]="this.model.termsOfServiceAgreement == false">
        Register
      </button>
    </form>

    <p>Already have an account? <a href="/login">Sign In</a></p>
  </mat-card>
</div>
