import * as inflection from 'inflection';
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerAppState } from '../../state/index.reducers';
import { firstValueFrom, map, Observable } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  orderActions,
  orderCardActions,
  PaymentService,
  selectOrderCardState,
} from '@aa/angular/core';
import { OrderCardResourceTypeMappings } from '@aa/nest/resource';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ViewBarComponent } from '../../components/view-bar/view-bar.component';

@Component({
  selector: 'aa-review-cards-view',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, ViewBarComponent],
  templateUrl: './review-cards-view.component.html',
  styleUrl: './review-cards-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewCardsViewComponent {
  draftId$: Observable<number | undefined>;
  cards$: Observable<OrderCardResourceTypeMappings['resourceWithRelationsT'][]>;

  backPath$: Observable<string>;

  constructor(
    private readonly store: Store<CustomerAppState>,
    private readonly route: ActivatedRoute,
    private readonly paymentService: PaymentService,
  ) {
    this.cards$ = this.store
      .select((s) => selectOrderCardState(s).items)
      .pipe(takeUntilDestroyed());
    this.draftId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('draftId')!)),
      takeUntilDestroyed(),
    );
    this.backPath$ = this.draftId$.pipe(
      map((draftId) => `/draft-editor/${draftId}`),
    );

    this.draftId$.subscribe((draftId) => {
      if (draftId) {
        this.store.dispatch(
          orderActions.loadItem({
            id: draftId,
            include: {
              orderServiceLinkages: true,
            },
          }),
        );
        this.store.dispatch(
          orderCardActions.loadItems({
            query: {
              where: {
                orderId: draftId,
              },
              include: {
                orderCardAttributes: {
                  include: {
                    orderCardAttributeType: true,
                  },
                },
                orderCardServiceLinkages: {
                  include: {
                    offeredService: true,
                  },
                },
              },
            },
          }),
        );
      }
    });
  }

  displayCardAttributeValue(fieldName: string | undefined, value: string) {
    let attributeValue = value;
    if (
      fieldName == 'pokemonType' &&
      !!attributeValue &&
      attributeValue.includes('[')
    ) {
      attributeValue = JSON.parse(value).join(', ');
    }
    return inflection.titleize(`${attributeValue ?? ''}`.replaceAll('-', ' '));
  }

  isString(value: string) {
    return typeof value == 'string';
  }

  async checkout() {
    const draftId = await firstValueFrom(this.draftId$);
    if (draftId) {
      const { url } = await firstValueFrom(
        this.paymentService.getCheckoutSessionURL(draftId),
      );
      if (url) location.href = url;
    }
  }
}
