import { CoreAppState } from '../state/core-app.state';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

const httpOptions = {
  withCredentials: true,
};

@Injectable()
export class PaymentService {
  constructor(
    private readonly http: HttpClient,
    private store: Store<CoreAppState>,
  ) {}

  getSetupPaymentMethodURL() {
    return this.http.post<{ url: string }>(
      `${environment.paymentService}/get-setup-payment-method-url`,
      {},
      httpOptions,
    );
  }

  getPaymentMethodStatus() {
    return this.http.post<{ setup: boolean }>(
      `${environment.paymentService}/get-payment-method-status`,
      {},
      httpOptions,
    );
  }

  getCheckoutSessionURL(orderId: number) {
    return this.http.post<{ url: string }>(
      `${environment.paymentService}/checkout-session-url`,
      { orderId },
      httpOptions,
    );
  }

  getCustomerPortalURL() {
    return this.http.post<{ url: string }>(
      `${environment.paymentService}/generate-customer-portal-url`,
      {},
      httpOptions,
    );
  }

  associateCustomerFromSession(userId: number, sessionId: string) {
    return this.http.post(
      `${environment.paymentService}/associate-customer-from-session`,
      { userId, sessionId },
      httpOptions,
    );
  }
}
