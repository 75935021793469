<aa-view-bar
  [backPath]="(backPath$ | async)!"
  title="Review Cards"></aa-view-bar>

<div class="customer-view-container v-scrollable">
  <div class="customer-view-sections">
    <div class="section">
      <h2>Cards</h2>
      <div class="cards">
        @for (card of cards$ | async; track card.id) {
          <div class="card">
            <div class="images">
              <img [src]="card.frontImageURL" />
              <img [src]="card.backImageURL" />
            </div>
            <div class="details">
              <div class="services">
                <h3>Services</h3>

                @for (
                  serviceLinkage of card.orderCardServiceLinkages;
                  track $index
                ) {
                  <tr>
                    <p>
                      {{ serviceLinkage.offeredService.name }}
                    </p>
                  </tr>
                }
              </div>

              <h3>Attributes</h3>
              <table>
                @for (attribute of card.orderCardAttributes; track $index) {
                  @if (
                    attribute.value &&
                    (!isString(attribute.value) ||
                      attribute.value.length > 0) &&
                    attribute.value != '""' &&
                    attribute.value != '"null"' &&
                    attribute.value != 'null' &&
                    attribute.value != 'undefined' &&
                    attribute.value != '"undefined"'
                  ) {
                    <tr>
                      <td class="label">
                        {{ attribute.orderCardAttributeType.name }}
                      </td>
                      <td>
                        {{
                          displayCardAttributeValue(
                            attribute.orderCardAttributeType?.fieldName,
                            attribute.value
                          )
                        }}
                      </td>
                    </tr>
                  }
                }
              </table>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>

<div class="customer-view-actions">
  <button mat-flat-button color="primary" (click)="checkout()">
    Submit Order
  </button>
</div>
