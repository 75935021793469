import {
  CoreAppState,
  offeredServiceActions,
  orderActions,
  selectAuth,
  selectOrderState,
} from '@aa/angular/core';
import { OrderResourceTypeMappings } from '@aa/nest/resource';
import { CommonModule, Location } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { debounceTime, firstValueFrom, Observable } from 'rxjs';
import { ViewBarComponent } from '../../components/view-bar/view-bar.component';

interface DraftFormModel {
  name: string;
  requestedServices: number[];
  notes: string;
}

@Component({
  selector: 'aa-draft-editor-view',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    FormlyModule,
    MatButtonModule,
    MatIconModule,
    ViewBarComponent,
  ],
  templateUrl: './draft-editor-view.component.html',
  styleUrl: './draft-editor-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraftEditorViewComponent {
  form = new FormGroup({});
  model: DraftFormModel = {
    name: '',
    requestedServices: [],
    notes: '',
  };
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      props: {
        label: 'Name',
        required: true,
      },
    },
    // {
    //   key: 'requestedServices',
    //   hide: true,
    //   type: 'select',
    //   props: {
    //     label: 'Requested Services',
    //     options: this.store
    //       .select((s) => selectOfferedServiceState(s).items)
    //       .pipe(
    //         map((items) =>
    //           items.map((item) => ({ label: item.name, value: item.id })),
    //         ),
    //       ),
    //     multiple: true,
    //     required: false,
    //   },
    // },
    {
      key: 'notes',
      type: 'textarea',
      defaultValue: '',
      props: {
        type: 'textarea',
        label: 'Notes',
        rows: 6,
        placeholder:
          'Include any other important details for specific requests',
      },
    },
  ];

  loading$: Observable<boolean>;
  error$: Observable<string | null | undefined>;

  initialLoadComplete = false;
  draftOrder$: Observable<
    OrderResourceTypeMappings['resourceWithRelationsT'] | undefined | null
  >;

  constructor(
    private readonly store: Store<CoreAppState>,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    this.store.dispatch(orderActions.clearCurrent());
    this.error$ = this.store
      .select((s) => selectOrderState(s).error)
      .pipe(takeUntilDestroyed());
    this.loading$ = this.store
      .select((s) => selectOrderState(s).currentLoading)
      .pipe(takeUntilDestroyed());

    this.draftOrder$ = this.store
      .select((s) => selectOrderState(s).current)
      .pipe(takeUntilDestroyed());
    this.draftOrder$.subscribe((order) => {
      if (order) {
        this.location.replaceState(`/draft-editor/${order.id}`);

        if (!this.initialLoadComplete) {
          // set model

          setTimeout(() => {
            this.form.setValue({
              name: order.name,
              // requestedServices: (order.orderServiceLinkages ?? [])?.map(
              //   (li) => li.offeredServiceId,
              // ),
              notes: order.notes ?? '',
            });

            this.initialLoadComplete = true;

            this.form.valueChanges
              .pipe(debounceTime(1000))
              .subscribe(() => this.save());
          }, 0);
        }
      }
    });

    const draftId = this.route.snapshot.paramMap.get('draftId');
    if (draftId) {
      // load existing
      this.store.dispatch(
        orderActions.loadItem({
          id: draftId,
          include: {
            orderCards: true,
            orderServiceLinkages: true,
          },
        }),
      );
    } else {
      // create new
      this.store
        .select((s) => selectAuth(s).user)
        .pipe(takeUntilDestroyed())
        .subscribe((user) => {
          if (user?.customerProfileId) {
            this.store.dispatch(
              orderActions.createItem({
                data: {
                  customerProfileId: user.customerProfileId,
                  name: 'New Draft',
                  notes: '',
                },
                andLoadToCurrent: true,
              }),
            );
          }
        });
    }

    this.store.dispatch(offeredServiceActions.loadItems({}));
  }

  async save() {
    const formData = this.model;
    const draft = await firstValueFrom(this.draftOrder$);

    if (draft?.id) {
      this.store.dispatch(
        offeredServiceActions.handleOrderLinkages({
          data: {
            orderId: draft.id,
            linkages: formData.requestedServices.map((id) => ({
              offeredServiceId: id,
            })),
          },
        }),
      );
      this.store.dispatch(
        orderActions.updateItem({
          id: draft.id,
          data: {
            name: formData.name,
            notes: formData.notes ?? '',
          },
        }),
      );
    }
  }
}
