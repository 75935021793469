import { OrderServiceLinkage, Prisma } from '@prisma/client';
import { CreateOrderServiceLinkageDTO } from './dto/create-order-service-linkage.dto';
import { UpdateOrderServiceLinkageDTO } from './dto/update-order-service-linkage.dto';
import { QueryOrderServiceLinkageDTO } from './dto/query-order-service-linkage.dto';

import {
  BaseResourceTypeMappings,
  MapPossibleIncludesToRequiredDepth,
} from '@aa/nest/common';
import { XOR } from '@aa/ts/common';

export type OrderServiceLinkageResourceTypeMappings = BaseResourceTypeMappings<
  OrderServiceLinkage,
  OrderServiceLinkage &
    Exclude<
      Partial<
        Prisma.OrderServiceLinkageGetPayload<{
          include: MapPossibleIncludesToRequiredDepth<
            Prisma.OrderServiceLinkageInclude,
            4
          >;
        }>
      >,
      OrderServiceLinkage
    >,
  {
    orderId: number;
    offeredServiceId: number;
  },
  XOR<
    Prisma.OrderServiceLinkageCreateInput,
    Prisma.OrderServiceLinkageUncheckedCreateInput
  >,
  XOR<
    Prisma.OrderServiceLinkageUpdateInput,
    Prisma.OrderServiceLinkageUncheckedUpdateInput
  >,
  Prisma.OrderServiceLinkageWhereInput,
  Prisma.OrderServiceLinkageWhereUniqueInput,
  Prisma.OrderServiceLinkageInclude,
  Prisma.OrderServiceLinkageOrderByWithRelationInput,
  CreateOrderServiceLinkageDTO,
  UpdateOrderServiceLinkageDTO,
  QueryOrderServiceLinkageDTO
>;

export const orderServiceLinkageCompositeKeyOrder: (keyof OrderServiceLinkageResourceTypeMappings['resourceT'])[] =
  ['orderId', 'offeredServiceId'];
