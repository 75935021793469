import { ActionReducer, createReducer, INIT, MetaReducer } from '@ngrx/store';
import {
  authReducer,
  CoreAppState,
  customerProfileReducer,
  offeredServiceReducer,
  orderCardAttributeReducer,
  orderCardAttributeTypeReducer,
  orderCardReducer,
  orderReducer,
  orderServiceLinkageReducer,
  orderStatusReducer,
} from '@aa/angular/core';

export interface CustomerAppState extends CoreAppState {}

function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    console.log(`[ACTION]${action.type}`, action, state);

    return reducer(state, action);
  };
}

const hydrationMetaReducer = (
  reducer: ActionReducer<CustomerAppState>,
): ActionReducer<CustomerAppState> => {
  return (state, action) => {
    if (action.type === INIT) {
      const storageValue = localStorage.getItem('state');
      if (storageValue) {
        try {
          return JSON.parse(storageValue) as CustomerAppState;
        } catch {
          localStorage.removeItem('state');
        }
      }
    }
    const nextState = reducer(state, action);
    const copyOfState = {
      auth: { ...nextState?.['auth'], paymentMethodSetup: undefined },
    };
    localStorage.setItem('state', JSON.stringify(copyOfState));
    return nextState;
  };
};

export const customerAppMetaReducers: MetaReducer[] = [
  debug,
  hydrationMetaReducer,
];

export const customerAppReducers = {
  isStaffApp: createReducer(false),
  auth: authReducer,
  order: orderReducer,
  orderCard: orderCardReducer,
  offeredService: offeredServiceReducer,
  orderServiceLinkage: orderServiceLinkageReducer,
  orderStatus: orderStatusReducer,
  orderCardAttribute: orderCardAttributeReducer,
  orderCardAttributeType: orderCardAttributeTypeReducer,
  customerProfile: customerProfileReducer,
};
