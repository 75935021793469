import { ActionLogEffects } from '@aa/angular/core';
import {
  AuthEffects,
  CustomerProfileEffects,
  OfferedServiceEffects,
  OrderCardAttributeEffects,
  OrderCardAttributeTypeEffects,
  OrderCardEffects,
  OrderEffects,
  OrderServiceLinkageEffects,
  OrderSourceEffects,
  OrderStatusEffects,
  RefreshTokenEffects,
  StaffProfileEffects,
  UserEffects,
  UserRoleAssignmentEffects,
  UserRoleEffects,
} from '@aa/angular/core';
import { OrderCardServiceLinkageEffects } from '@aa/angular/core';

export const staffAppEffects = [
  AuthEffects,
  OrderEffects,
  OrderCardEffects,
  UserEffects,
  UserRoleEffects,
  UserRoleAssignmentEffects,
  RefreshTokenEffects,
  ActionLogEffects,
  OfferedServiceEffects,
  OrderServiceLinkageEffects,
  OrderSourceEffects,
  OrderStatusEffects,
  OrderCardAttributeEffects,
  OrderCardAttributeTypeEffects,
  CustomerProfileEffects,
  StaffProfileEffects,
  OrderCardServiceLinkageEffects,
];
